import { createInstance } from '@adobe/alloy';

export default class FlutterAdobeAnalytics {
    constructor() {
        this.alloy = createInstance({
            name: "alloy",
        });
        this.isAlloyInitialized = false;
        this.isAlloyInprogress = false;
        
        window.__flutter_adobe_analytics = this;
    }

    /**
     * @param {String} edgeConfigId
     * @param {String} orgId
     */
    configureWithAppId(edgeConfigId, orgId) {
        if (this.alloy) {
            this.isAlloyInprogress = true
            this.alloy("configure", {
                "edgeConfigId": edgeConfigId,
                "orgId": orgId
            }).then((/** @type {any} */ result) => {
                this.isAlloyInprogress = false
                this.isAlloyInitialized = true;
            }).catch((/** @type {any} */ error) => {
                this.isAlloyInprogress = false
                console.error("Error in configuring Adobe Analytics in web: ", error);
            });
        }
    }

    /**
     * @param {String} action
     * @param {any} data
     */
    sendEvents(action, data) {
        if (this.alloy && this.isAlloyInitialized) {
            this.alloy("sendEvent", {
                "renderDecisions": true,//REQUIRED FOR THE ADOBE CAMPAIN TO BE RENDERED
                "type": action,
                "data": JSON.parse(data),
                "documentUnloading": true,
                "xdm": {
                    // TODO : Implementation of as part of FLUXUI-56366 
                }
            }).catch((/** @type {any} */ error) => {
                console.error("Error sending event to Adobe Analytics in web : ", error);
            });
        } else if (this.isAlloyInprogress) {
            this.checkIsAlloyInprogress(() => {
                this.sendEvents(action, data);
            });
        }
    }

    /**
     * @param {String} state
     * @param {any} data
     */
    sendState(state, data) {
        if (this.alloy && this.isAlloyInitialized) {
            this.alloy("sendEvent", {
                "renderDecisions": true, //REQUIRED FOR THE ADOBE CAMPAIN TO BE RENDERED
                "type": state,
                "data": JSON.parse(data),
                "documentUnloading": true,
                "xdm": {
                    // TODO : Implementation of as part of FLUXUI-56366
                }
            }).catch((/** @type {any} */ error) => {
                console.error("Error sending event to Adobe Analytics : ", error);
            });
        } else if (this.isAlloyInprogress) {
            this.checkIsAlloyInprogress(() => {
                this.sendEvents(state, data);
            });
        }
    }

    checkIsAlloyInprogress = (callback) => {
        if (this.isAlloyInprogress) {
            setTimeout(() => {
                this.checkIsAlloyInprogress(callback);
            }, 100);
        } else {
            callback();
        }
    }
}